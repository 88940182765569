<template>
  <div>
    <l-button
      v-auth="'AUTH_APP-SAVE'"
      type="primary"
      class="c-top_right_btn"
      @click="editRow()"
    >
      新建系统
    </l-button>
    <!-- 列表区域-STR -->
    <l-table-common
      :data-source="tableData"
      @change="search"
    >
      <l-table-column
        data-index="id"
        title="流水号"
      />
      <l-table-column
        data-index="code"
        title="英文编码"
      />
      <l-table-column
        data-index="cnName"
        title="中文名字"
      />
      <l-table-column
        data-index="descript"
        title="系统描述"
      />
      <l-table-column title="类型">
        <template slot-scope="scope">{{ scope.type === 0?'内部系统':'外部系统' }}</template>
      </l-table-column>

      <l-table-column title="操作">
        <template
          v-if="scope.type !== 0"
          slot-scope="scope"
        >
          <l-button
            v-auth="'AUTH_APP-SAVE'"
            type="link"
            @click="editRow(scope)"
          >
            编辑
          </l-button>
        </template>
      </l-table-column>
    </l-table-common>
    <!-- 列表区域-END -->

    <!-- 保存记录-STR -->
    <l-modal
      v-model="editDialog.show"
      :title="editDialog.data.id?'修改系统':'添加系统'"
      :confirm-loading="editDialog.save"
      width="600px"
      cancel-text="取消"
      ok-text="确认"
      @ok="saveDialog"
    >
      <l-form-model
        ref="form"
        :model="editDialog.data"
        :rules="rules"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 16 }"
      >
        <l-form-model-item label="英文编码" prop="code" required>
          <l-input
            v-model="editDialog.data.code"
            placeholder="请输入英文编码"
          />
        </l-form-model-item>

        <l-form-model-item label="中文名字" prop="cnName" required>
          <l-input
            v-model="editDialog.data.cnName"
            placeholder="请输入中文名字"
          />
        </l-form-model-item>

        <l-form-model-item label="系统描述" prop="descript" required>
          <l-input
            v-model="editDialog.data.descript"
            placeholder="请输入系统描述"
          />
        </l-form-model-item>
      </l-form-model>
    </l-modal>
    <!-- 保存记录-END -->
  </div>
</template>

<script>
export default {
  name: 'AuthAppList',
  data() {
    return {
      tableData: {
        isLoading: false,
        total: 0,
        page: 1,
        size: 10,
        list: []
      },
      editDialog: {
        data: {
          code: '',
          cnName: '',
          descript: ''
        },
        save: false,
        show: false
      },
      rules: {
        code: [{ required: true, message: '请输入英文编码', trigger: 'change' }],
        cnName: [{ required: true, message: '请输入中文名字', trigger: 'change' }],
        descript: [{ required: true, message: '请输入系统描述', trigger: 'change' }]
      }
    }
  },
  mounted() {
    this.search(1)
  },
  methods: {
    async search(page) {
      page = (page && page.current) || page || this.tableData.page || 1
      this.tableData.isLoading = true

      try {
        const data = await this.$store.dispatch('auth/authAppList', {
          page: page,
          size: this.tableData.size
        })
        this.tableData.isLoading = false
        this.tableData.list = data.list || []
        this.tableData.total = data.count
        this.tableData.page = page
      } catch (e) {
        this.tableData = this.$options.data().tableData
      }
    },
    editRow(row) {
      row = row || {}
      this.editDialog.data = JSON.parse(JSON.stringify(row))
      this.editDialog.show = true
    },
    async saveDialog() {
      this.editDialog.save = true
      try {
        await this.$refs.form.validate()
        await this.$store.dispatch('auth/authAppSave', {
          id: this.editDialog.data.id,
          code: this.editDialog.data.code,
          cnName: this.editDialog.data.cnName,
          descript: this.editDialog.data.descript
        })
        this.editDialog = this.$options.data().editDialog
        this.search()
      } catch (e) {
        this.editDialog.save = false
      }
    }
  }
}
</script>
